import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Layout from '@/components/Base/Layout';
import SEO from '@/components/Base/seo';
import Card, { Post } from '@/components/Card';
// import NewsBlock from '@/components/NewsBlock';
import { useScrollPosition } from '@/hooks/useScrollPosition';

const PostGallery = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1rem;
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  margin: auto;
  @media ${({ theme }) => theme.breakpoints.beyondmaxwidth} {
    padding: 0;
  }
  ${Post} {
    height: 100%;
    @media ${({ theme }) => theme.breakpoints.mobile} {
      width: 100%;
    }
    @media ${({ theme }) => theme.breakpoints.nonmobile} {
      width: 50%;
    }
  }
`;
const initialCounter = 4;
const IndexPage = ({ pageContext }) => {
  const { posts: notFirstPost, news, featuredPost: firstPost, navigation } = pageContext;
  const [counter, setCounter] = useState(initialCounter);
  const [posts, setPosts] = useState(notFirstPost.slice(0, initialCounter));

  useScrollPosition(
    ({ currPos }) => {
      const fullOffset =
        document.documentElement.scrollHeight -
        document.documentElement.getElementsByTagName('footer')[0].clientHeight -
        document.body.clientHeight;
      const isShow = -currPos.y >= fullOffset;
      if (isShow) {
        const newCounter = counter + initialCounter;
        setPosts([...posts, ...notFirstPost.slice(counter, newCounter)]);
        setCounter(newCounter);
      }
    },
    [news, counter],
    undefined,
    undefined,
    100
  );
  const {
    slug: featuredSlug,
    id: featuredId = '',
    publishedAt: featuredPublishedDate = '',
    title: featuredTitle = '',
    author: featuredAuthor = '',
    subtitle: featuredSubtitle = '',
    image: featuredImage = '',
    timetoread: featuredTimeToRead = '',
  } = firstPost;
  return (
    <Layout navigation={navigation}>
      <SEO title="Главная" keywords={['cvlh', 'games', 'culture', 'культура', 'игры', 'cybervalhalla', 'videogames']} />
      <Card
        link={featuredSlug}
        id={featuredId}
        publishDate={featuredPublishedDate}
        title={featuredTitle}
        author={featuredAuthor}
        subtitle={featuredSubtitle}
        image={featuredImage}
        createdAt={featuredPublishedDate}
        timetoread={featuredTimeToRead}
        featured
      />
      {/* <NewsBlock news={news} /> */}
      <PostGallery>
        {!!posts.length &&
          posts.map((node, id) => {
            const {
              id: cardId = '',
              slug = '',
              publishedAt = '',
              title = '',
              author = '',
              subtitle = '',
              image = '',
              timetoread = '',
            } = node;
            return (
              <Card
                key={cardId}
                link={slug}
                id={cardId}
                publishDate={publishedAt}
                title={title}
                author={author}
                subtitle={subtitle}
                image={image}
                createdAt={publishedAt}
                timetoread={timetoread}
                count={id}
              />
            );
          })}
      </PostGallery>
    </Layout>
  );
};

IndexPage.propTypes = {
  pageContext: PropTypes.any.isRequired,
};

export default IndexPage;
